import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import {
    Button,
    ButtonGroup,
    Card, CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    List, ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';
import BackButton from 'Components/Common/Buttons/BackButton';
import CopyToClipboard from 'Components/Common/CopyToClipboard/CopyToClipboard';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import NotesDialog from 'Components/Common/Dialogs/NotesDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import SearchInput from 'Components/Common/Inputs/SearchInput';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Map from 'Components/Common/Map/Map';
import TabBar from 'Components/Common/Navigation/TabBar';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import SearchCreditNote from 'Components/CustomerReturns/SearchCreditNote/SearchCreditNote';
import CustomerAccounts from 'Components/Customers/ViewCustomer/CustomerAccounts/CustomerAccounts';
import OrderSearch from 'Components/Customers/ViewCustomer/OrderSearch';
import QuotationSearch from 'Components/Customers/ViewCustomer/QuotationSearch';   
import Reminder from 'Components/Reminders/Misc/Reminder';
import SalesReports from 'Components/Reports/SalesReports/SalesReports';
import ViewCustomerReturns from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ViewCustomerReturns';
import Reminders from 'Components/Reminders/Reminders/Reminders';

import AddReminderDialog from './AddReminderDialog';
import AddressCard from './AddressCard';
import CustomerImportantInformation from './CustomerImportantInformation';
import CustomerNote from './CustomerNote';
import CustomerNotes from './CustomerNotes';
import EngineerJobSheets from './EngineerJobSheets';
import EngineerMedia from 'Components/Common/EngineerMedia/ViewEngineerMedia';
import Greenaware from './Greenaware';
import SiteAddresses from './SiteAddresses';
import Marketing from './Marketing';
import MailingLists from './MailingLists';
import Surveys from './Surveys';

import { colors, CustActivityLog } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

import addressAccounts from 'Assets/Images/markers/addressAccounts.png';
import addressRegistered from 'Assets/Images/markers/addressRegistered.png';
import addressSite from 'Assets/Images/markers/addressSite.png';
import addressTrading from 'Assets/Images/markers/addressTrading.png';
import { Dial, DialAdornment } from 'Functions/MiscFunctions';
import CallHistory from 'Components/Phone/CallHistory';
import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails';
import Equipment from './Equipment';
import ViewPartsSold from './ViewPartsSold';
import YoYsReports from 'Components/Reports/YoYsReports/YoYsReports';


const initialState = () => ({
    key: 1,
    formData: {
        contactId: ''
    },
    currentTab: 'customer', //default 'customer'
    keywords: '',
    showDeletedContacts: 0,
    customer: {},
    customerContacts: {},
    countyList: [],
    countryList: [],
    deleteOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false,
    cards: [],
    tabAccess: {
        customer:          true,       
        RegisteredAddress: false,
        TradingAddresses:  false,
        AccountAddresses:  false,
        SiteAddresses:     false, 
        Contacts:          false,
        JobSheets:         false,
        Em:                false,
        Greenaware:        false,
        Quotations:        false,
        Orders:            false,
        CreditNote:        false,
        Accounts:          false,
        Reports:           false,
        Notes:             false,
        PhoneHistory:      false,
        Reminders:         false,
        ActivityLog:       false,
        addOrder:          false,
        addQuote:          false,
        CustomerReturns:   false,
        ActivityLog:       false,
        Marketing:         false,
        MailingLists:      false,
        equipment:         false,
        PartsSold:         false,
    },
    access: {
        viewContact:        false,
        updateContact:      false,
        creditNote:         false,
        addCreditNote:      false,
        viewCreditLimit:    false,
        updateCreditLimit:  false,
        processCreditLimit: false,
        salesReport:        false,
        importantInformation: false,
    },
    currentCredit: 0,
    creditLimit: 'Loading...',
    confirmNoteDelete : 0,
    showAddReminderDialog: 0,
    ReminderList: {
        value: 'All',
        list:  []
    },
    firstLoad: true,

    showInactiveAccountsSites: true,
    showInactiveTradingSites: true,
});

const styles = theme => ({
    paperGrey: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#f8f9fa'
    },
    paperDisabled: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#fcc6c5',
    },
    disabledText: {
        color: 'black !important',
    },
    paper: {
        padding: theme.spacing(2)
    },
    card: {
        color: '#fff',
        padding: '20px'
    },
    linkStyle:{
        textDecoration:'none',
        color: '#fff',
    },
    blueLinks:{
        textDecoration:'none',
        color: colors.blue,
    },
    spacing2 : {
        marginBottom : '2em'
    },
    spacing1 : {
        marginBottom : '1em'
    }
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

class ViewCustomer extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Customer:View:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        this.checkAccess();
        this.getTabAccess();
    }

    getTabAccess = () => {
        let tabs = {
            RegisteredAddress: 'view-customer-tab-registered-address',
            TradingAddresses:  'view-customer-tab-trading-addresses',
            AccountAddresses:  'view-customer-tab-account-addresses',
            SiteAddresses:     'view-customer-tab-site-addresses', 
            Contacts:          'view-customer-tab-contacts',
            JobSheets:         'view-customer-tab-job-sheets',
            Em:                'view-customer-tab-em',
            Greenaware:        'view-customer-tab-greenaware',
            Quotations:        'view-customer-tab-quotations',
            Orders:            'view-customer-tab-orders',
            CreditNote:        'view-customer-tab-credit-note',
            Accounts:          'view-customer-tab-accounts',
            Reports:           'view-customer-tab-reports',
            PhoneHistory:      'view-customer-tab-phone-history',
            Notes:             'view-customer-tab-notes',
            Reminders:         'view-customer-tab-reminders',
            CustomerReturns:   'view-customer-customer-returns',
            ActivityLog:       'view-customer-tab-activity-log',
            Marketing:         'view-customer-tab-marketing',
            Survey:            'view-customer-tab-survey',
            MailingLists:      'view-customer-tab-mailinglists',
            Emails:            'view-customer-tab-emails',
            equipment:         'view-customer-tab-equipment',
            PartsSold:         'view-customer-tab-parts-sold',
            yoysReport:        'view-customer-tab-yoys-reports',
        }
        API.multiAccess(
            _.values(tabs)
        ).then(res => {
            let tabAccess = this.state.tabAccess;
            let keys = _.keys(tabs);
            _.each(res, (r, idx) => {
                tabAccess[keys[idx]] = r;
            })
            this.setState({tabAccess})
        })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.match?.params?.id !== prevProps.match?.params?.id) {
            this.setState({
                isLoading: true,
                currentTab: initialState().currentTab,
                firstLoad: true,
            }, () => {
                this.getCustomer(this.props.match.params.id)
                // this.getCreditLimit();
            })
        }
    }
    checkAccess = () => {
        API.multiAccess([
            'view-customer-contact',
            'update-customer-contact', 
            'credit-note',
            'add-credit-note',
            'view-customer-credit-limit',
            'update-customer-credit-limit',
            'process-customer-credit-limit',
            'sales-reports',
            'add-order',
            'add-quotation',
            'add-customer-contact',
            'add-customer-address',
            'view-customer-important-information',
            'view-customer-tab-activity-log'
        ]).then(([viewContact, updateContact, creditNote, addCreditNote, viewCreditLimit, updateCreditLimit, processCreditLimit, salesReport, addOrder, addQuote, addContact, addAddress, importantInformation, activityLog]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewContact:           viewContact,
                    updateContact:         updateContact,
                    creditNote:            creditNote,
                    addCreditNote:         addCreditNote,
                    viewCreditLimit:       viewCreditLimit,
                    updateCreditLimit:     updateCreditLimit,
                    processCreditLimit:    processCreditLimit,
                    salesReport:           salesReport,
                    addOrder:              addOrder,
                    addQuote:              addQuote,
                    addContact:            addContact,
                    addAddress:            addAddress,
                    importantInformation:  importantInformation,
                    activityLog:           activityLog,
                }
            }, () => {
                this.getCustomer(this.props.match.params.id);
                // if (viewCreditLimit) this.getCreditLimit();
            });
        });
    }
    getCustomer = (customerId = this.props.match.params.id) => {
        API.get(`/customers/${customerId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if (result.data) {
                this.populateDropdowns();
                this.getContacts();
                this.getCustomerDashboardData();
                this.setState({
                    customer: result.data,
                    isLoading: false,
                }, () => {
                    if(this.state.currentTab === 'customer' && this.state.customer.cust_important_info && this.state.firstLoad) this.displayImportantInfo();
                    this.setState({firstLoad: false});
                    if (this.props.match.params.tab) this.goToTab();
                    this.handleChangeReminderType();
                    this.getActivityLog();
                    this.updateKey();
                    this.getCreditLimit();
                })
            }
        });
    };

    displayImportantInfo = () => {
        if ( this.state.access.importantInformation ) {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        }
    }

    updateKey = () => {this.setState({key:uuidv4()})}

    getActivityLog = () => {
        API.get(`/customers/${this.state.customer.cust_id}/activityLog`)
        .then( (result) => {
            if (!result.data.errors) {
                _.map(result.data, (log) => {
                    let data = CustActivityLog(log.t);
                    log.color = data['color'];
                    log.icon = data['icon'];
                    log.time = moment(log.d).format("DD/MM/YYYY");
                });
                this.setState({
                    customer: {
                        ...this.state.customer,
                        activity_log: result.data
                    },
                });
            }
        });
    }

    getCustomerDashboardData = () => {
        API.get(`/customers/${this.props.match.params.id}/sales/dashboard`)
        .then((res) =>  {
			res.data.forEach((card) => {
				let statCount = 0;
				for (let i = 0; i <= 3; i++) { 
					if(card.stats[i] && card.stats[i].info.length === 0){
						statCount++;
					}
				}
				card.backgroundColour = (statCount === card.stats.length ? colors.blue : colors.red);
			});
            this.setState({
                cards: res.data
            });
        })
    }
    showHideInfo = (idx, statIdx) => {
		let data =  [...this.state.cards];
        data[idx] = {
            ...data[idx],
            expandedStat: data[idx].expandedStat === statIdx ? false : statIdx
		};
        this.setState({
			cards: data
		});
	};
    displayCard = (card, idx) => {
		if(((card.hasAccess)) ){
			return (
				<Grid key={idx} item xs={12} md={6} lg={4} xl={3}>
					<Paper className={this.props.classes.card} style={{backgroundColor: card.backgroundColour ? card.backgroundColour : colors.red}}>
						<Typography variant="h5" gutterBottom style={{color:'white'}}>
							<FALightIcon icon={card.icon} style={{color:'white'}} />
							{card.heading}
						</Typography>
						<Divider style={{backgroundColor:'white', marginBottom: '30px'}}/>
						{card.stats.map((stat, statIdx) => (
							<Grid key={statIdx} container spacing={3}>
								<Grid item xs={10}> {stat.name} </Grid>
								<Grid item xs={2} style={{textAlign: 'right'}}>
									{stat.info.length}
								</Grid>
							</Grid>
						))}
					</Paper>
                    <Paper className={this.props.classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{backgroundColor:'#f2f2f2', textAlign: 'center'}}>
                                <Link to={card.linkUrl} style={{ textDecoration:'none' }}>
                                    <Button fullWidth>
                                        {card.linkText}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
				</Grid>
			)
		}
    }
    populateDropdowns = () => {
        let countyList = [];
        let countryList = [];
        Promise.all([
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all')
        ])
        .then(([countyRes, countryRes]) =>  {
            if(countyRes.data){
                countyList = _.map(countyRes.data, el => {
                    return _.assign({
                        value: el.county_id,
                        label: el.county_name,
                    });
                });
            }
            if(countryRes.data){
                countryList = _.map(countryRes.data, el => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                    });
                });
            }
            this.setState({
                countyList: countyList,
                countryList: countryList
            });
        });
    }
    handleTabChange = (event, value) => {
        if (['customer','Contacts', 'SiteAddresses', 'AccountAddresses', 'TradingAddresses', 'RegisteredAddress'].includes(value)) {  this.state.customer.cust_important_info && this.displayImportantInfo(); }
        this.setState({
            currentTab: value
        }, ()=>{this.savePageState();});
    };
    deleteContact() {
        API.post(`/customers/contacts/${this.state.formData.contactId}/delete`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.getContacts();
            }
        });
    }
    restoreContact() {
        API.post(`/customers/contacts/${this.state.formData.contactId}/restore`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.getContacts();
            }
        });
    }
    handleDeleteOpen = (rowData) => {
        this.setState({
            formData:{
                ...this.state.formData,
                contactId: rowData.contact_id
            },
            deleteOpen: true,
        });
    }
    handleDeleteClose = () => {
        this.setState({ 
            deleteOpen: false 
        });
    }
    handleDeleteSuccess = () => {
        this.setState({ 
            deleteOpen: false 
        });
        this.deleteContact();
    }
    handleRestoreOpen = (rowData) => {
        this.setState({
            formData:{
                ...this.state.formData,
                contactId: rowData.contact_id
            },
            restoreOpen: true,
        });
    }
    handleRestoreClose = () => {
        this.setState({ 
            restoreOpen: false 
        });
    }
    handleRestoreSuccess = () => {
        this.setState({ 
            restoreOpen: false 
        });
        this.restoreContact();
    }
    getContacts = () => {
        API.get(`/customers/${this.props.match.params.id}/contacts` , {
            params: {
                keywords:       this.state.keywords,
                showDeleted:    this.state.showDeletedContacts
            }
        })
        .then((result) => {
            this.setState({
                customerContacts: result.data
            })
        });
    }
    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        });
    };
    resetContacts = () => {
        this.setState({
            keywords: ''
        },
        () => {
            this.getContacts();
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }
    handleDeleteNote = (id) => {
        this.setState({
            confirmNoteDelete : id
        });
    }
    handleCloseDeleteNote = () => {
        this.setState({
            confirmNoteDelete : 0
        });
    }
    deleteNote = () => {
        API.post(`/misc/notes/${this.state.confirmNoteDelete}/delete`, {type : "customer-notes"})
            .then(result => {
                if (!result.data.error) this.getCustomer()
                this.setState({
                    confirmNoteDelete : 0
                });
            })
        this.handleCloseDeleteNote();
    }
    
    //speed up adding and reloading when using reminders
    getReminders = () => {
        API.get(`/customers/${this.props.match.params.id}/reminders`)
            .then( res => {
                if (!res.error){
                    this.setState({
                        customer: {
                            ...this.state.customer,
                            reminders: res.data
                        }
                    }, this.handleChangeReminderType());
                }
            });
    }

    getUpcomingReminder = () => {
        return _.head(_.filter(this.state.customer.reminders, (item) => {
            return (!moment(item.reminder_due_datetime).isBefore(moment()))
        }));
    }

    handleChangeReminderType = ( e = null ) => {
        var value = e?.value ? e.value : this.state.ReminderList.value;
        var list = _.filter(this.state.customer.reminders, item => {
            switch(value){
                case 'Overdue':     return (moment(item.reminder_due_datetime).isBefore(moment()));
                case 'Due Today':   return (moment(item.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment().endOf('day')) && !moment(item.reminder_due_datetime).isBefore(moment()));
                case 'Due Later':   return !(moment(item.reminder_due_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment().endOf('day')));
                default:            return 1;
            }
        }) 
        this.setState({ ReminderList: { value, list } }, ()=>{this.savePageState();});
    }

    goToTab = () => {
        let currentTab = this.props.match.params.tab;
        this.setState({currentTab});
    }

    showDeletedContacts = () => {
        this.setState({
            showDeletedContacts: this.state.showDeletedContacts ? 0 : 1
        }, ()=>{this.getContacts()})
    }

    getCreditLimit = () => {
        if (this.state.access.viewCreditLimit){
            this.setState({creditLimit: 'Loading...'},
                () => {
                    API.get(`/customers/${this.props.match.params.id}/accounts/addresses`)
                    .then(res => {
                        if (res.data) {
                            this.setState({ 
                                currentCredit:  _.sumBy(res.data,'t'), 
                                creditLimit:    _.sumBy(res.data,'g'),
                            });
                        }
                    })
                }
            );
        } 
    }

    handleRemoveLink = targetId => {
        API.post(`/customers/${this.props.match.params.id}/linkToCustomer/delete`, {targetId})
        .then(res => this.getCustomer(this.props.match.params.id));
    }

    changeCustomerSubscribeStatus = () => {
        API.post(`/customers/${this.props.match.params.id}/subscribe`)
        .then(res => this.getCustomer(this.props.match.params.id));
    }

    showInactiveAccountsSites = () => {
        this.setState({
            showInactiveAccountsSites: !this.state.showInactiveAccountsSites
        })
    }

    showInactiveTradingSites = () => {
        this.setState({
            showInactiveTradingSites: !this.state.showInactiveTradingSites
        })
    }

    render() {
        const { customer, currentTab, customerContacts, isLoading, access, ReminderList, tabAccess } = this.state;
        const { classes } = this.props;

        const notes             = (customer?.notes) ? _.orderBy(customer.notes,['cn_updated_at_datetime'],['desc'])     : [];
        const upcomingReminder  = (customer?.reminders) ? this.getUpcomingReminder(): null;

        const tabItems = [
                                                    {name: 'customer',          label: `Customer` }, 
                                                    {name: 'RegisteredAddress', label: `Registered Address` }, 
                                                    {name: 'TradingAddresses',  label: `Trading Addresses (${this.state.customer?.trading_addresses && this.state.customer?.trading_addresses.length})` }, 
                                                    {name: 'AccountAddresses',  label: `Account Addresses (${this.state.customer?.account_addresses && this.state.customer?.account_addresses.length})` }, 
                                                    {name: 'SiteAddresses',     label: `Site Addresses (${this.state.customer?.site_addresses && this.state.customer?.site_addresses.length})` },  
                                                    {name: 'Contacts',          label: `Contacts (${this.state.customerContacts && this.state.customerContacts.length})` }, 
                                                    {name: 'JobSheets',         label: 'Job Sheets' },
                                                    {name: 'Em',                label: 'Media' },
                                                    {name: 'Greenaware',        label: 'Greenaware' },
                                                    {name: 'Quotations',        label: `Quotations` },  
                                                    {name: 'Orders',            label: `Orders` },
            this.state.access.creditNote      &&    {name: 'CreditNote',        label: `Credit Notes` },
                                                    {name: 'CustomerReturns',   label: `Customer Returns` },
            this.state.access.viewCreditLimit &&    {name: 'Accounts',          label: `Accounts` },
            this.state.access.salesReport     &&    {name: 'Reports',           label: `Sales Report` },
                                                    {name: 'yoysReport',        label: `YoY Sales Report` },
                                                    {name: 'PhoneHistory',      label: 'Phone History' },
                                                    {name: 'Notes',             label: `Notes (${this.state.customer?.notes_count + ((this.state.customer?.cust_important_info != '' && this.state.customer?.cust_important_info)? 1 : 0)})` },
                                                    {name: 'Reminders',         label: `Reminders` },
                                                    {name: 'Marketing',         label: `Marketing` },
                                                    {name: 'Survey',            label: `Surveys` },
                                                    {name: 'MailingLists',      label: `Mailing Lists` },
                                                    {name: 'PartsSold',         label: `Parts Sold` },
                                                    {name: 'Emails',            label: `Emails (${this.state.customer.emails_count})` },
                                                    {name: 'equipment',         label: `Equipment`},
                                                    {name: 'ActivityLog',       label: `Activity Log` },
        ]

        if (this.state.redirect) {
            return <Redirect to="/customers/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            View Customer
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                {(isLoading && (
                                    <LoadingCircle />
                                )) || (
                                    <>
                                        <TabBar
                                            tabs={_.map(_.filter(tabItems, i => i?.label && tabAccess[i.name]), i => ({label: i.label, value: i.name}))}
                                            currentTab={currentTab} 
                                            handleTabChange={this.handleTabChange}
                                        />
                                        {customer.account_status.cas_name != 'Active - Verified' && ['customer', 'Contacts', 'SiteAddresses', 'AccountAddresses', 'TradingAddresses', 'RegisteredAddress'].includes(currentTab) &&
                                            <Grid xs={12} spacing={3} style={{padding:'1em', color:'white', backgroundColor: colors.red}}>
                                                <Typography style={{fontWeight:'bold'}}>{customer.cust_name} is {customer.account_status.cas_name}</Typography>
                                            </Grid>
                                        }
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                {currentTab === 'customer' &&
                                                    <TabContainer>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} lg={6} className="pb-1">
                                                                <Grid item style={{paddingTop:'2em'}}>
                                                                        <Typography variant="h6" gutterBottom className="float-left">
                                                                            Business Details
                                                                        </Typography>
                                                                        <Link to={`/customers/update/${this.props.match.params.id}`}  className="float-right p-1">
                                                                            <IconButton><FALightIcon icon='pencil-alt' noMargin button /></IconButton>
                                                                        </Link>
                                                                        {/*
                                                                            <IconButton edge="end" type='a' className="float-right p-1" href={`/customers/update/${this.props.match.params.id}`}>
                                                                                <FALightIcon icon='pencil' button noMargin/>
                                                                            </IconButton>
                                                                        */}
                                                                    </Grid>
                                                                <TextField
                                                                    label="Business Type"
                                                                    value={customer.cust_type}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                
                                                                <TextField
                                                                    label="Business Name"
                                                                    value={customer.cust_name}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Business Trading Name"
                                                                    value={customer.cust_trading_name}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Legal Status"
                                                                    value={customer.cust_legal_status}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Company Registration Number"
                                                                    value={customer.cust_reg_number}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Company VAT Number"
                                                                    value={customer.cust_vat_number}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Company EORI Number"
                                                                    value={customer.cust_eori_number}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Phone"
                                                                    value={customer.registered_address.address_phone}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    disabled
                                                                    InputProps={{
                                                                        startAdornment: <DialAdornment number={customer.registered_address.address_phone} />
                                                                    }}
                                                                    inputProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                    InputLabelProps={{
                                                                        className: classes.disabledText
                                                                    }}
                                                                />
                                                                <Grid container xs={12} >
                                                                    <Grid item xs={11}>
                                                                        <TextField
                                                                            label="Email"
                                                                            value={customer.registered_address.address_email}
                                                                            margin="normal"
                                                                            id="cust_Email"
                                                                            fullWidth
                                                                            disabled
                                                                            inputProps={{
                                                                                className: classes.disabledText
                                                                            }}
                                                                            InputLabelProps={{
                                                                                className: classes.disabledText
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1} style={{padding:'1em'}} >
                                                                        <CopyToClipboard field="cust_Email" disabled={1} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} >
                                                                    <Grid item xs={11}>
                                                                        <TextField
                                                                            label="Website"
                                                                            id="cust_Website"
                                                                            value={customer.registered_address.address_website}
                                                                            margin="normal"
                                                                            fullWidth
                                                                            disabled
                                                                            inputProps={{
                                                                                className: classes.disabledText
                                                                            }}
                                                                            InputLabelProps={{
                                                                                className: classes.disabledText
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1} style={{padding:'1em'}} >
                                                                        <CopyToClipboard field="cust_Website" disabled={1} />
                                                                    </Grid>
                                                                </Grid>     
                                                                <Grid item xs={12} spacing={3} style={{marginBottom:'calc(1.1em - 2px)'}}>
                                                                    <Grid item style={{paddingTop:'2em'}}>
                                                                        <Typography variant="h6" gutterBottom className="float-left">
                                                                            Account Information
                                                                        </Typography>
                                                                        {access.viewCreditLimit &&
                                                                            <Link to={`/customers/update/${this.props.match.params.id}`}  className="float-right p-1">
                                                                                <IconButton><FALightIcon icon='pencil-alt' noMargin button /></IconButton>
                                                                            </Link>
                                                                        }
                                                                    </Grid>
                                                                    <TextField
                                                                        label="Customer Account Reference"
                                                                        value={'CS'+customer.cust_id}
                                                                        margin="normal"
                                                                        fullWidth
                                                                        disabled
                                                                        inputProps={{
                                                                            className: classes.disabledText
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: classes.disabledText
                                                                        }}
                                                                    />
                                                                    <Grid container item>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="VAT Type"
                                                                                value={customer.vat_type && customer.vat_type.vat_type_name}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                style={{width: '95%'}}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="VAT Value"
                                                                                value={customer.vat_type && customer.vat_type.default_vat_value.vat_value_percentage + '%'}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container item>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="Discount Name"
                                                                                value={customer.discount_name && customer.discount_name.cdn_name}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                style={{width: '95%'}}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="Discount Value"
                                                                                value={customer.discount_name && customer.discount_name.default_discount_value.cdv_percentage + '%'}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="Payment Term"
                                                                                value={customer.payment_term && customer.payment_term.payment_term_name}
                                                                                margin="normal"
                                                                                fullWidth
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                style={{width: '95%'}}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="Account Status"
                                                                                value={customer.account_status.cas_name}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputProps={{
                                                                                    startAdornment: <FASolidIcon 
                                                                                                        icon='circle'
                                                                                                        size='small'
                                                                                                        style={{color: (customer.account_status.cas_name == 'Active - Verified' ? colors.green : colors.red) }}
                                                                                                    /> 
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="Monthly Service Plan"
                                                                                value={customer.cust_service_plan ? 'Yes' : 'No'}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                fullWidth
                                                                                style={{width: '95%'}}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                label="Yearly Service Plan"
                                                                                value={customer.cust_yearly_service_plan ? 'Yes' : 'No'}
                                                                                margin="normal"
                                                                                disabled
                                                                                inputProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    className: classes.disabledText
                                                                                }}
                                                                                fullWidth
                                                                                style={{width: '95%'}}
                                                                            />
                                                                        </Grid>
                                                                        {!!this.state.access.viewCreditLimit &&
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    label="Credit Limit"
                                                                                    value={this.state.creditLimit === 'Loading...' ? this.state.creditLimit : '£'+parseFloat(this.state.creditLimit).toFixed(2)}
                                                                                    margin="normal"
                                                                                    disabled
                                                                                    inputProps={{
                                                                                        className: classes.disabledText
                                                                                    }}
                                                                                    InputProps={{
                                                                                        startAdornment: <FASolidIcon 
                                                                                                            icon='circle'
                                                                                                            size='small'
                                                                                                            style=  {{color: ( this.state.currentCredit > this.state.creditLimit ) ? 
                                                                                                                        colors.red : 
                                                                                                                        (((this.state.creditLimit - this.state.currentCredit) < (this.state.creditLimit * 0.1 ) ? 
                                                                                                                            colors.orange : 
                                                                                                                            colors.green 
                                                                                                                        )) 
                                                                                                                    }}
                                                                                                        /> 
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        className: classes.disabledText
                                                                                    }}
                                                                                    fullWidth
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={12} lg={6} style={{alignContent: 'flex-start'}}>
                                                                <Grid container item xs={12}>
                                                                    <Grid item xs={12}  className={classes.spacing2}>
                                                                        <Typography variant="h6" gutterBottom className="float-left">
                                                                            Quick Links
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid  xs={12}>
                                                                        <Grid>
                                                                            <ButtonGroup fullWidth variant="contained"  color='primary'>
                                                                                <Button  component="a"   href={`/sales/quotation/add/${this.props.match.params.id}`} disabled={!access.addQuote || ((customer.cust_account_status_id === 3) ? true : false)}>
                                                                                    <FALightIcon icon={'handshake'} style={{color: (customer.cust_account_status_id === 3) ? 'rgba(0, 0, 0, 0.26)' : '#fff' }}/>
                                                                                    Add Quotation
                                                                                </Button>
                                                                                <Button  component="a"  href={`/sales/order/add/customer/${this.props.match.params.id}`} disabled={!access.addOrder || ((customer.cust_account_status_id === 3 || customer.cust_account_status_id === 2) ? true : false)}>
                                                                                    <FALightIcon icon={'handshake'} style={{color: (customer.cust_account_status_id === 3 || customer.cust_account_status_id === 2) ? 'rgba(0, 0, 0, 0.26)' : '#fff' }}/>
                                                                                    Add Order
                                                                                </Button>
                                                                                {access.addCreditNote &&
                                                                                    <Button  component="a"  href={`/returns/creditnote/add/customer/${this.props.match.params.id}`} disabled={(customer.cust_account_status_id === 3 || customer.cust_account_status_id === 2) ? true : false}>
                                                                                        <FALightIcon icon={'box'} style={{color: (customer.cust_account_status_id === 3 || customer.cust_account_status_id === 2) ? 'rgba(0, 0, 0, 0.26)' : '#fff' }}/>
                                                                                        Add Credit Note
                                                                                    </Button>
                                                                                }
                                                                            </ButtonGroup>
                                                                        </Grid>
                                                                        <Grid style={{paddingTop:'1em'}}>
                                                                            <ButtonGroup fullWidth variant="contained"  color='primary'>
                                                                                <Button disabled={!access.addContact} component="a"  href={`/customers/contacts/add/${this.props.match.params.id}`}>
                                                                                    <FALightIcon icon={'users'} style={{color:'#fff'}}/>
                                                                                    Add Contact
                                                                                </Button>
                                                                                <Button disabled={!access.addAddress} component="a"  href={`/customers/addresses/add/${this.props.match.params.id}`}>
                                                                                    <FALightIcon icon={'users'} style={{color:'#fff'}}/>
                                                                                    Add Address
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </Grid>
                                                                        <Grid style={{paddingTop:'1em'}}>
                                                                            <ButtonGroup fullWidth variant="contained"  color='primary' >
                                                                                <Button  
                                                                                    onClick={() => this.props.deployDialog(<NotesDialog type="customer-notes" id={this.props.match.params.id} callback={this.getCustomer} />, 'Add Notes', '', 'xs')}
                                                                                    disabled={!tabAccess.Notes}
                                                                                >
                                                                                    <FALightIcon icon={'pen'} style={{color:'#fff'}}/>
                                                                                    Add Note
                                                                                </Button>
                                                                                {!customer.cust_important_info &&
                                                                                    <Button  
                                                                                        onClick={() => this.props.deployDialog(<NotesDialog type="customer-important-info" id={this.props.match.params.id} callback={this.getCustomer} />, 'Add Important Information', '', 'xs')}
                                                                                        disabled={!access.importantInformation}
                                                                                    >
                                                                                        <FALightIcon icon={'pen'} style={{color:'#fff'}}/>
                                                                                        Add Important Info
                                                                                    </Button>
                                                                                }
                                                                                <Button  
                                                                                    onClick={ () => this.props.deployDialog(<AddReminderDialog callback = {this.getCustomer} customer = {customer} />,'Add Reminder', '', 'xs')}
                                                                                    disabled={!tabAccess.Reminders}
                                                                                >
                                                                                    <FALightIcon icon='alarm-clock' style={{color:'#fff'}}/>
                                                                                    Add Reminder
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </Grid>
                                                                        <Grid style={{paddingTop:'1em'}}>
                                                                            <Button
                                                                                fullWidth
                                                                                variant='contained'
                                                                                color={customer.cust_unsubscribed ? 'primary' : 'secondary'}
                                                                                onClick={()=>this.props.deployConfirmation(
                                                                                    customer.cust_unsubscribed ? 
                                                                                    'Are you sure you want to resubscribe this customer?' :
                                                                                    'Are you sure you want to unsubscribe this customer?',
                                                                                    customer.cust_unsubscribed ?
                                                                                    'Resubscribe Customer' :
                                                                                    'Unsubscribe Customer',
                                                                                    this.changeCustomerSubscribeStatus
                                                                                )}
                                                                            >
                                                                                {customer.cust_unsubscribed ?
                                                                                    'Resubscribe Customer To' :
                                                                                    'Unsubscribe Customer From'
                                                                                } Marketing
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {access.importantInformation &&
                                                                    <Grid container xs={12}>
                                                                        <Grid container item xs={12} >
                                                                            {customer.cust_important_info &&
                                                                                <Grid container item xs={12} >
                                                                                    <Grid item xs={6}>
                                                                                        <Typography variant="h6" gutterBottom className="float-left">
                                                                                            Important Information
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} className={classes.spacing2}>
                                                                                        <CustomerImportantInformation 
                                                                                            customer=   {customer}
                                                                                            success=    {this.getCustomer}
                                                                                            editable
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                <Grid container xs={12}>
                                                                    <Grid container item xs={12} >
                                                                        {notes.length > 0 && !!tabAccess.Notes &&
                                                                            <Grid container item xs={12} >
                                                                                <Grid item xs={12} className={classes.spacing2}>
                                                                                    <CustomerNotes 
                                                                                        notes={notes} 
                                                                                        deployDialog={this.props.deployDialog} 
                                                                                        editCallback={this.getCustomer}    
                                                                                        deleteCallback={this.handleDeleteNote}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12}>
                                                                    {upcomingReminder &&
                                                                        <Grid container item xs={12} >
                                                                            <Grid item xs={3}>
                                                                                <Typography variant="h6" gutterBottom className={classes.spacing1}>
                                                                                    Upcoming Reminder
                                                                                </Typography>
                                                                            </Grid>
                                                                            
                                                                            {customer.reminders && 
                                                                                <Grid key={upcomingReminder.reminder_id} item xs={12}>
                                                                                    <Reminder 
                                                                                        key={upcomingReminder.reminder_id}
                                                                                        callback={this.getCustomer}
                                                                                        model={upcomingReminder} 
                                                                                        onClick={() => this.handleViewTask(upcomingReminder.task_id)} 
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                                {customer.linked.length > 0 &&
                                                                    <Grid container xs={12}>
                                                                        <Grid item xs={12}>
                                                                            <PaddedPaper>
                                                                                <Grid container item xs={12}>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="h6" gutterBottom className={classes.spacing1}>
                                                                                            Linked Customers
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <List style={{padding:0}}>
                                                                                            {_.map(customer.linked, i => 
                                                                                                <ListItem key={i.clc_id}>
                                                                                                    <ListItemText primary={`${i.customer.cust_name} - ${i.customer.cust_trading_name}`} />
                                                                                                    <ListItemSecondaryAction>
                                                                                                        <AllIcon
                                                                                                            icon={icons.customer}
                                                                                                            onClick={() => this.props.history.push(`/customers/view/${i.customer.cust_id}`)}
                                                                                                            noMargin
                                                                                                        />
                                                                                                        <AllIcon
                                                                                                            icon={icons.delete}
                                                                                                            onClick={() => this.props.deployConfirmation('Are you sure you want to remove this customer link?', 'Remove Customer Link', () => this.handleRemoveLink(i.customer.cust_id))}
                                                                                                            noMargin
                                                                                                        />
                                                                                                    </ListItemSecondaryAction>
                                                                                                </ListItem>
                                                                                            )}
                                                                                        </List>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </PaddedPaper>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                <Grid container style={{marginTop: '1.5em'}}>
                                                                    <Grid item xs={12}>
                                                                        <>
                                                                            <Map 
                                                                                showSwapMapType
                                                                                controllable
                                                                                style={{cursor: 'default !important'}}
                                                                                markers={_.filter([customer.registered_address, ...customer.account_addresses, ...customer.trading_addresses, ...customer.site_addresses], site => site.address_lang && site.address_long).map(i => ({
                                                                                    lat:    i.address_lang,
                                                                                    lng:    i.address_long,
                                                                                    marker: <img src={( i.address_type === 'Accounts' && addressAccounts ) || (
                                                                                        ( i.address_type === 'Registered' && addressRegistered ) || (
                                                                                            ( i.address_type === 'Trading' && addressTrading ) || addressSite
                                                                                        )
                                                                                    )} width={20} style={{marginTop: '-50%'}} />,
                                                                                    content: 
                                                                                        <PaddedPaper>
                                                                                            <Grid container spacing={1}>
                                                                                                <Grid item xs={12}><Typography variant="body1" component="body1">{i.address_name}</Typography></Grid>
                                                                                                <Grid item xs={12}>
                                                                                                    {i.address_line_one}
                                                                                                    {i.address_line_two   ? ', ' + i.address_line_two   : ''}
                                                                                                    {i.address_line_three ? ', ' + i.address_line_three : ''}
                                                                                                    {i.address_town       ? ', ' + i.address_town       : ''}
                                                                                                    {i.address_postcode   ? ', ' + i.address_postcode   : ''}
                                                                                                </Grid>
                                                                                                <Grid item xs={12}> {i.address_email}</Grid>
                                                                                                <Grid item xs={12}> {i.address_phone}</Grid>
                                                                                                <Grid item xs={12} className='buttonRow'>
                                                                                                    <Button variant='outlined' onClick={()=>{ window.location.href = `/customers/addresses/view/${i.address_id}` }} >
                                                                                                        <AllIcon icon={icons.search}/>View 
                                                                                                    </Button>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </PaddedPaper>
                                                                                }))}
                                                                                zoom={5}
                                                                                height={500}
                                                                            />
                                                                        </>
                                                                    </Grid>
                                                                </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className='buttonRow' style={{float:'right'}}>
                                                                <BackButton props={this.props} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </TabContainer>
                                                }
                                                {currentTab === 'RegisteredAddress' &&
                                                    <TabContainer>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={12} lg={4}>
                                                                <AddressCard address={customer.registered_address} classes={classes} />
                                                            </Grid>
                                                        </Grid>
                                                    </TabContainer>
                                                }
                                                {currentTab === 'TradingAddresses' &&
                                                    <TabContainer>
                                                        <Grid container item spacing={3}>
                                                            {_.filter(customer.trading_addresses, site => !site.address_active).length > 0 &&
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        style={{marginLeft: '1em'}}
                                                                        control={
                                                                            <Switch label="Hide Inactive" color='primary'  value={this.state.showInactiveTradingSites} onClick={this.showInactiveTradingSites}/>
                                                                        }
                                                                        label="Hide Inactive"
                                                                    />
                                                                </Grid>
                                                            }
                                                            {_.map(_.filter(customer.trading_addresses, site => 
                                                                (!this.state.showInactiveTradingSites && !site.address_active) ? 0 : 1
                                                            ),(item, idx) => {
                                                                return (
                                                                    <Grid item xs={12} lg={4} key={idx}>
                                                                        <AddressCard address={item} classes={classes} access={this.state.access} />
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </TabContainer>
                                                }
                                                {currentTab === 'AccountAddresses' &&
                                                    <TabContainer>
                                                        <Grid container item spacing={3}>
                                                            {_.filter(customer.account_addresses, site => !site.address_active).length > 0 &&
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        style={{marginLeft: '1em'}}
                                                                        control={
                                                                            <Switch label="Hide Inactive" color='primary'  value={this.state.showInactiveAccountsSites} onClick={this.showInactiveAccountsSites}/>
                                                                        }
                                                                        label="Hide Inactive"
                                                                    />
                                                                </Grid>
                                                            }
                                                            {_.map(_.filter(customer.account_addresses, site => 
                                                                (!this.state.showInactiveAccountsSites && !site.address_active) ? 0 : 1
                                                            ),(item, idx) => {
                                                                return (
                                                                    <Grid item xs={12} lg={4} key={idx}>
                                                                        <AddressCard address={item} classes={classes} access={this.state.access} />
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </TabContainer>
                                                }
                                                {currentTab === 'SiteAddresses' &&
                                                    <TabContainer>
                                                        <SiteAddresses key={this.state.key} addresses={customer.site_addresses} classes={classes}/>
                                                    </TabContainer>
                                                }
                                                {currentTab === 'MailingLists' &&
                                                    <TabContainer>
                                                        <MailingLists customer={customer} />
                                                    </TabContainer>
                                                }
                                                {currentTab === 'Contacts' &&
                                                    <TabContainer>
                                                        <Grid container item spacing={3}>
                                                            <Grid item xs={4}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Contacts
                                                                </Typography>
                                                                <SearchInput 
                                                                    placeholder="Search..."
                                                                    value={this.state.keywords}
                                                                    onChange={this.handleSearchKeyword}
                                                                    getContacts={this.getContacts}
                                                                    onClick={this.resetContacts}
                                                                />
                                                                <FormControlLabel
                                                                    style={{marginLeft: '1em'}}
                                                                    control={
                                                                        <Switch label="Show Deleted" color='primary'  value={this.state.showDeletedContacts} onClick={this.showDeletedContacts}/>
                                                                    }
                                                                    label="Show Deleted"
                                                                />
                                                                
                                                                <br></br>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DataTable  
                                                                config={{
                                                                    key: 'contact_id',
                                                                    pagination: false,
                                                                    plainHeader: true
                                                                }}
                                                                columns={[
                                                                    {
                                                                        heading: 'Name',
                                                                        field: rowData => rowData.contact_name
                                                                    },
                                                                    {
                                                                        heading: 'Position',
                                                                        field: rowData => rowData.contact_position
                                                                    },
                                                                    {
                                                                        heading: 'Telephone',
                                                                        field: rowData => <Dial number={rowData.contact_telephone} />
                                                                    },
                                                                    {
                                                                        heading: 'Mobile',
                                                                        field: rowData => <Dial number={rowData.contact_mobile} />
                                                                    },
                                                                    {
                                                                        heading: 'Email',
                                                                        field: rowData => <>
                                                                            {rowData.contact_email} {rowData.contact_email && <CopyToClipboard copy={rowData.contact_email} />}
                                                                        </>
                                                                    },
                                                                    {
                                                                        heading: 'Associated Addresses',
                                                                        field: rowData => rowData.addresses.length
                                                                    },
                                                                    {
                                                                        actions: rowData => {
                                                                            return [
                                                                                {name: rowData.contact_status == 'Active' ? 'Delete' : 'Restore', icon: rowData.contact_status == 'Active' ? 'trash-alt' : 'trash-restore', onClick: rowData.contact_status == 'Active' ? this.handleDeleteOpen : this.handleRestoreOpen },
                                                                                {name: 'Update', icon: 'pencil-alt', link: '/customers/contacts/update/' + rowData.contact_id, disabled: !access.updateContact, disabled: rowData.contact_status != 'Active'},
                                                                                {name: 'View', icon: 'search', link: '/customers/contacts/view/' + rowData.contact_id, disabled: !access.viewContact, disabled: rowData.contact_status != 'Active'},
                                                                            ]
                                                                        }
                                                                    }
                                                                ]}
                                                                rows={customerContacts}
                                                            />
                                                        </Grid>
                                                    </TabContainer>
                                                }
                                                 {/* ACTIVITY LOG */}
                                                {currentTab === 'ActivityLog' &&
                                                    <TabContainer>
                                                        {!customer?.activity_log?.length ? <>No activity has been monitored yet</> :
                                                            <Card>
                                                                <CardContent>
                                                                    <ActivityLog 
                                                                        filter={
                                                                            [
                                                                                {label: 'Customer', types: ['Customer Created', 'Edited Customer', 'Active - Verified', 
                                                                                    'Closed', 'On Hold', 'Name Changed', 'Active - Not Verified', 'Credit Statement Sent']},
                                                                                {label: 'Sites',    types: ['Address Added', 'Edited Address']},
                                                                                {label: 'Contacts', types: ['Contact Added', 'Edited Contact', 'Contact Deleted']},
                                                                                {label: 'Notes',    types: ['Note Added', 'Edited Note', 'Note Deleted']},
                                                                                {label: 'Reminders',types: ['Reminder Added', 'Reminder Deleted', 'Edited Reminder', 'Snoozed Reminder', 'Edited Reminder', 'Deleted Reminder']},
                                                                                {label: 'Sales',    types: ['Order Created', 'Quote Created' ]},
                                                                                {label: 'Customer Returns',    types: ['Customer Return Created', 'Credit Note Created', 'Greenaware Return Acknowledged']},
                                                                                {label: 'Marketing',    types: ['Marketing Preference Removed', 'Marketing Preference Added', 'Unsubscribed Marketing', 'Subscribed Marketing' ]},
                                                                            ]
                                                                        }
                                                                        fields={{
                                                                            type:   't',
                                                                            date:   'd',
                                                                            icon:   { color: 'color', icon: 'icon' },
                                                                            text:   'b',
                                                                            staff:  's',
                                                                        }}
                                                                        atl={_.filter(customer?.activity_log, 'd')}
                                                                    />
                                                                </CardContent>
                                                            </Card>
                                                        }
                                                    </TabContainer>
                                                }
                                                {currentTab === 'Notes' &&
                                                    <TabContainer>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Notes
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button 
                                                                    variant="contained" 
                                                                    color="primary" 
                                                                    onClick={() => this.props.deployDialog(<NotesDialog type="customer-notes" id={this.props.match.params.id} callback={this.getCustomer} />, 'Add Notes', '', 'xs')}
                                                                >
                                                                    <FALightIcon icon='plus' buttonPrimary /> 
                                                                    Add Notes
                                                                </Button>
                                                                {!customer.cust_important_info &&
                                                                    <Button  
                                                                        variant=    "contained" 
                                                                        color=      "primary" 
                                                                        style=      {{marginLeft: '1.5em'}}
                                                                        onClick=    {() => this.props.deployDialog(<NotesDialog type="customer-important-info" id={this.props.match.params.id} callback={this.getCustomer} />, 'Add Important Information', '', 'xs')}
                                                                    >
                                                                        <FALightIcon icon={'plus'} style={{color:'#fff'}}/>
                                                                        Add Important Information
                                                                    </Button>
                                                                }
                                                            </Grid>
                                                            {customer?.cust_important_info && 
                                                                <Grid container spacing={3} item xs={12}>
                                                                    <Grid item xs={12}>
                                                                        <CustomerImportantInformation 
                                                                            customer={customer} 
                                                                            success={this.getCustomer} 
                                                                            editable />
                                                                    </Grid>
                                                                </Grid>
                                                            }   
                                                            <Grid container spacing={3} item xs={12}>
                                                                {customer?.notes.length > 0 ? (
                                                                    <>
                                                                       {customer?.notes.map(note => (
                                                                           <Grid item xs={12}>
                                                                                <CustomerNote 
                                                                                    note={note} 
                                                                                    deployDialog={this.props.deployDialog} 
                                                                                    editCallback={this.getCustomer}    
                                                                                    deleteCallback={this.handleDeleteNote}
                                                                                />
                                                                            </Grid>
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <Typography component="p">
                                                                        There are no notes to show for this customer
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </TabContainer>
                                                }
                                                {currentTab === 'Reminders' &&
                                                    <TabContainer>
                                                        {/* <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={3} style={{alignItems: 'center'}}>
                                                                    <Grid item xs={2}>
                                                                        <FormControl margin="none" fullWidth>
                                                                            <AutoCompleteSelect 
                                                                                options={[
                                                                                    {value: 'All', label: 'All'},
                                                                                    {value: 'Overdue', label: 'Overdue'},
                                                                                    {value: 'Due Today', label: 'Due Today'},
                                                                                    {value: 'Due Later', label: 'Due Later'},
                                                                                ]} 
                                                                                label='Reminder Due'
                                                                                onChange={this.handleChangeReminderType}
                                                                                value={ReminderList.value}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={3} >
                                                                        <Button 
                                                                            variant="contained" 
                                                                            color="primary" 
                                                                            onClick={ () => 
                                                                                this.props.deployDialog(<AddReminderDialog callback = {this.getCustomer} customer = {customer} />,'Add Reminder', '', 'xs')
                                                                            }
                                                                        >
                                                                            <FALightIcon icon='plus' buttonPrimary /> 
                                                                            Add Reminder
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid container spacing ={3} item xs={12}>
                                                                        {ReminderList.list ? ReminderList.list.map((reminder, idx) =>
                                                                            <Grid key={idx} item xs={7}>
                                                                                <Reminder 
                                                                                    key={reminder.reminder_id}
                                                                                    callback={this.getCustomer}
                                                                                    model={reminder} 
                                                                                    onClick={() => this.handleViewTask(reminder.task_id)} 
                                                                                />
                                                                            </Grid> 
                                                                        ) : <></>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                        <Reminders customerId={this.state.customer.cust_id}/>
                                                    </TabContainer>
                                                }
                                                {currentTab === 'Quotations'      && <QuotationSearch cid={this.state.customer.cust_id} />}
                                                {currentTab === 'Orders'          && <OrderSearch noMargin cid={this.state.customer.cust_id} />}
                                                {currentTab === 'CreditNote'      && <SearchCreditNote cid={this.state.customer.cust_id} />}
                                                {currentTab === 'Accounts'        && <CustomerAccounts customer={this.state.customer} access={this.state.access}/>}
                                                {currentTab === 'PhoneHistory'    && <TabContainer><CallHistory customerId={this.props.match.params.id} inPage /></TabContainer>}
                                                {currentTab === 'Reports'         && <SalesReports history={this.props.history} cid={this.state.customer.cust_id} />}
                                                {currentTab === 'yoysReport'      && <YoYsReports history={this.props.history} cid={this.state.customer.cust_id} />}
                                                {currentTab === 'PartsSold'       && <TabContainer><ViewPartsSold cid={this.state.customer.cust_id} /></TabContainer>}
                                                {currentTab === 'Em'              && <TabContainer><EngineerMedia customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'JobSheets'       && <TabContainer><EngineerJobSheets customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'Greenaware'      && <TabContainer><Greenaware customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'Marketing'       && <TabContainer><Marketing history={this.props.history} customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'Survey'          && <TabContainer><Surveys history={this.props.history} customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'CustomerReturns' && <TabContainer><ViewCustomerReturns history={this.props.history} customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'Emails'          && <TabContainer><ViewEmails customerId={this.props.match.params.id}/></TabContainer>}
                                                {currentTab === 'equipment'       && <TabContainer><Equipment customerId={this.props.match.params.id}/></TabContainer>}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                    <ConfirmationDialog 
                        close   = {this.handleCloseDeleteNote} 
                        open    = {this.state.confirmNoteDelete}
                        title   = "Delete Note?"
                        message = "Are you sure you want to delete this note?"
                        success = {this.deleteNote}
                    />
                    <ConfirmationDialog 
                        open={this.state.deleteOpen} 
                        success={this.handleDeleteSuccess} 
                        close={this.handleDeleteClose} 
                        title="Delete Contact?" 
                        message="Are you sure you want to delete this contact?"
                    />
                    <ConfirmationDialog 
                        open={this.state.restoreOpen} 
                        success={this.handleRestoreSuccess} 
                        close={this.handleRestoreClose} 
                        title="Restore Contact?" 
                        message="Are you sure you want to restore this contact?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully deleted the contact'
                    />
                </Grid>
            );
        }   
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                                  => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                           => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size, style) => dispatch(deployDialog(content, title, variant, size, style)),
    closeDialog:        ()                                     => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)              => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewCustomer));